import React, { useEffect, useContext } from 'react'
import userManager, { signinRedirectCallback } from 'utils/oidcConfig'
import { useHistory } from 'react-router-dom'
import { UserContext } from "context/UserContext";
import { authenticationService } from 'services/authentication';
import { i18n } from "localization/i18n";
import { setCookie } from "helpers/cookies";

function SigninOidc() {
  const history = useHistory()

  const { setAuthenticated, setUserMustChangePinCode } = useContext(UserContext);

  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback()
      const user = await userManager.getUser();
      
      if(user){
        i18n.changeLanguage(user.profile.lang);
        authenticationService.setToken(user);
        setAuthenticated(true);
        
        setCookie(process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME, user.profile.must_change_pin);
        setUserMustChangePinCode(user.profile.must_change_pin);
      }

	   history.push('/')
    }
    signinAsync()
  }, [history, setAuthenticated, setUserMustChangePinCode])

  return (<div></div>)
}

export default SigninOidc
