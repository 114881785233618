import { useEffect,useContext,useState } from "react";
import { useTranslation } from "react-i18next";
import { IDENTITY_CONFIG } from "utils/authConst";
import { UserContext } from "context/UserContext";

import { i18n } from "localization/i18n";

import { GridSection } from "../Layout/GridSection";
import { ChangePinForm } from "./ChangePinForm";
import { Footer } from "components/Layout/Footer/Footer"; 

export function ChangePin() {
  const { t } = useTranslation();
  var heartbeatUrl = `${IDENTITY_CONFIG.authority}/heartbeat`;
  const {busyFormItems} = useContext(UserContext);
  const [isKeepUserActive, setKeepUserActive] = useState(false);

  useEffect(() => {
    setKeepUserActive(busyFormItems.length > 0);
  }, [busyFormItems]);

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, []);

  return (
    <GridSection>
      {isKeepUserActive 
        ? <viedoc-inactivity-tracker heartbeat-url={heartbeatUrl} origin={window.origin} post-logout-uri="/logout" hot-reload is-single-page keep-alive></viedoc-inactivity-tracker>
        : <viedoc-inactivity-tracker heartbeat-url={heartbeatUrl} origin={window.origin} post-logout-uri="/logout" hot-reload is-single-page></viedoc-inactivity-tracker>}

      <h1 className="grid__title">{t("change_pin.must_change_pin")}</h1>
      <ChangePinForm className="grid__form" />
      <Footer alignText="left" className="grid__footer" />
    </GridSection>
  );
}
