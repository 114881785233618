import differenceInSeconds from "date-fns/differenceInSeconds";
import { eraseCookie, getCookie, setCookie } from "helpers/cookies";
import { signinSilent, signoutRedirect } from "utils/oidcConfig";
import userManager from "utils/oidcConfig";
import { history } from "router/history";

const GrantType = {
    Password: "password",
    RefreshToken: "refresh_token",
};

userManager.events.addUserSignedOut(async () => {
    eraseCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
    history.push('/signout-oidc');
});

userManager.signinSilentCallback();

let refreshedAt;
let tokenExpirationInSeconds;
let isRefreshing = false;

const setToken = (data) => {
    setLocalToken(data.access_token);
};

const isLoggedIn = () => {
    return !!getLocalToken();
};

const login = async (username, password) => {
    return setToken({
        grant_type: GrantType.Password,
        username,
        password,
    });
};

const logout = async () => {
    eraseCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
    await signoutRedirect();
};

const getLocalToken = () => getCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
const setLocalToken = (token) =>
    setCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME, token);


const refreshToken = async () => {
    var user = await signinSilent();
    setToken(user);
    return user;
};

const refreshThrottleIsExpired = () =>
    differenceInSeconds(Date.now(), refreshedAt) > tokenExpirationInSeconds / 2;

const tryRefreshToken = async () => {

    if (!isLoggedIn()) {
        return;
    }

    if (!isRefreshing && (refreshThrottleIsExpired() || !refreshedAt)) {
        isRefreshing = true;
        try {
            const { expires_at } = await refreshToken();
            tokenExpirationInSeconds = expires_at;
            refreshedAt = new Date(Date.now());
        } catch {
            await logout();
        } finally {
            isRefreshing = false;
        }
    }
};

export const authenticationService = {
    login,
    setToken,
    logout,
    tryRefreshToken,
    isLoggedIn,
};
