import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { MainHeader } from "../MainHeader/MainHeader";
import { Footer } from "./Footer/Footer";
import { UserContext } from "context/UserContext";
import "./MainLayout.css";
import "components/viedocui/index.js";
import { IDENTITY_CONFIG } from "utils/authConst";

var heartbeatUrl = `${IDENTITY_CONFIG.authority}/heartbeat`;

export function MainLayout({ children }) {
  const {busyFormItems} = useContext(UserContext);
  const [isKeepUserActive, setKeepUserActive] = useState(false);

  useEffect(() => {
    setKeepUserActive(busyFormItems.length > 0);
  }, [busyFormItems]);

  return (
    <div className="main-layout">
      {isKeepUserActive 
        ? <viedoc-inactivity-tracker heartbeat-url={heartbeatUrl} origin={window.origin} post-logout-uri="/logout" hot-reload is-single-page keep-alive></viedoc-inactivity-tracker>
        : <viedoc-inactivity-tracker heartbeat-url={heartbeatUrl} origin={window.origin} post-logout-uri="/logout" hot-reload is-single-page></viedoc-inactivity-tracker>}
      <div className="main-layout__content">
        <MainHeader />
        <main className="main-layout__inner-content">{children}</main>
        <Footer className="main-layout__footer" />
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any,
};
